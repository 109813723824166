const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	datafields: {
		get: `${API_URL}/${API_VERSION}/datafield`,
	},
	typeProfile: {
		get: `${API_URL}/${API_VERSION}/roles`,
	},
	brand: {
		get: `${API_URL}/${API_VERSION}/company/:customer_id/brands`,
	},
	service: {
		get: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service`,
	},
	area: {
		get: `${API_URL}/${API_VERSION}/company/filters/area`,
	},
	subarea: {
		get: `${API_URL}/${API_VERSION}/company/area/:area_id/subarea`,
	},
	locationPatient: {
		get: `${API_URL}/${API_VERSION}/company/filters/sub-area/:area_id`,
		save: `${API_URL}/${API_VERSION}/company/location-patient`,
	},
	call: {
		get: `${API_URL}/${API_VERSION}/company/filters/area`,
		statics: `${API_URL}/api/getAllCallStatus`,
	},
}

export { API_ROUTES }
