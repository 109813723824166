import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const { data } = await useJwt.get(API_ROUTES.typeProfile.get)

		return data
	},
}
